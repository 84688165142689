#navigation-section {
  .container-navbar {
    width: 1010px !important;
    height: 100%;
    max-width: 100%;
    margin: auto;
  }
  .navbar {
    padding: 0.5rem 0 !important;
  }

  #navigation {
    background-color: #000000 !important;

    .nav-logo {
      width: 24px;
      height: 24px;

      @media screen and (max-width: 767px) {
        width: 18px;
        height: 18px;
      }
    }

    #responsive-navbar-nav {
      padding-left: 1rem;
    }

    .navbar-nav {
      width: 100%;
      justify-content: space-around;
    }

    .tools-container {
      width: 100%;
      max-width: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .user-icon,
    .shop-icon,
    .shop-icon-sm {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }

    .user-icon {
      @media screen and (max-width: 767px) {
        margin-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    .shop-icon {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .logo-lg {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .logo-sm {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    .logo-sm-visible {
      width: 25px;
      height: 27px;
      cursor: pointer;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    .links {
      color: #ffffff;

      @media screen and (max-width: 767px) {
        margin-bottom: 1rem;
      }
    }

    .products-link {
      @media screen and (max-width: 767px) {
        margin-top: 1rem !important;
      }
    }

    .projects-link {
      @media screen and (max-width: 767px) {
        margin-bottom: 1rem !important;
      }
    }

    a {
      text-decoration: none;
      color: #afafaf;
      transition: all 0.5s;

      &:hover {
        color: #ffffff;
        text-decoration: none;
      }
    }

    .dropdown-toggle.nav-link {
      color: #afafaf !important;
      transition: all 0.5s;

      &:hover {
        color: #ffffff !important;
      }
    }

    .dropdown-menu {
      left: unset;
      background-color: #000000;
    }

    .dropdown-container {
      padding-left: 0.5rem;
    }

    .dropdown-menu-icons {
      width: 40px;
      height: auto;
      fill: blue;
    }

    .navbar-btn {
      margin-right: 0 !important;
    }

    .navbar-brand {
      padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    }

    #blog-link {
      color: blue;
      // padding: 8px;
    }
  }

  // media queries
  @media screen and (min-width: 768px) and (max-width: 850px) {
    .navbar-btn {
      padding-left: 1em !important;
      padding-right: 1em !important;
    }
  }

  @media screen and (min-width: 768px) {
    .navbar {
      .dropdown {
        position: unset;
      }

      .dropdown-item:active {
        background-color: transparent;
      }
      .dropdown-item:hover {
        background-color: transparent;
      }
      .dropdown-item {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
  }
}
