.ticket-section {
  background-color: #c80502;
  height: 240px;

  .ticket-container {
    @media screen and (max-width: 400px) {
      flex-direction: column-reverse !important;
    }
  }

  @media screen and (max-width: 991px) {
    height: 200px;
  }

  @media screen and (max-width: 767px) {
    height: 140px;
  }
  @media screen and (max-width: 665px) {
    height: 120px;
  }
  @media screen and (max-width: 400px) {
    height: auto;
  }

  .flags-container {
    transform: translateY(-30%);
    @media screen and (max-width: 1440px) {
      margin: 0 !important;
    }

    @media screen and (max-width: 991px) {
      transform: translateY(-40%);
    }
    @media screen and (max-width: 767px) {
      transform: translateY(-20%);
    }

    @media screen and (max-width: 400px) {
      transform: unset;
    }
  }

  .moto-flags-img {
    @media screen and (max-width: 400px) {
      max-width: 200px;
      transform: translateY(-20%);
    }
  }
  .moto-img {
    @media screen and (max-width: 991px) {
      margin-right: 1.5rem !important;
    }
    @media screen and (max-width: 767px) {
      margin-right: 1rem !important;
      max-width: 120px !important;
    }

    @media screen and (max-width: 565px) {
      display: none;
    }
  }
  br {
    @media screen and (max-width: 1050px) {
      display: none;
    }
  }

  .ticket-txt {
    @media screen and (max-width: 400px) {
      display: none;
    }
  }
  .mobile-ticket-txt {
    @media screen and (min-width: 400px) {
      display: none;
    }
  }
  .shop-btn {
    background-color: transparent;
    border: none;
    width: 230px;
    height: 48px;
    @media screen and (max-width: 991px) {
      width: 180px;
      height: 40px;
    }
    @media screen and (max-width: 767px) {
      width: 140px;
      height: 35px;
    }
    @media screen and (max-width: 565px) {
      width: 110px;
      height: 32px;
    }
    @media screen and (max-width: 400px) {
      width: 140px;
      height: 35px;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
    }

    &:hover {
      .shop-btn-shape {
        transform: translate(-50%, -50%) skew(-35deg);
      }
    }

    .shop-btn-shape {
      background-color: #ffffff;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) skew(-25deg);
      transition: 0.2s ease-out;
    }
    .shop-btn-txt {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      color: #c80502;
    }
  }

  .font-size-64 {
    font-size: 64px !important;
  }

  @media screen and (max-width: 1600px) {
    .font-size-64 {
      font-size: 60px !important;
    }
  }
  @media screen and (max-width: 1400px) {
    .font-size-64 {
      font-size: 50px !important;
    }
  }
  @media screen and (max-width: 1250px) {
    .font-size-64 {
      font-size: 44px !important;
    }
  }
  @media screen and (max-width: 991px) {
    .font-size-64 {
      font-size: 38px !important;
    }
  }
  @media screen and (max-width: 767px) {
    .font-size-64 {
      font-size: 31px !important;
    }
  }
  @media screen and (max-width: 665px) {
    .font-size-64 {
      font-size: 27px !important;
    }
  }
  @media screen and (max-width: 575px) {
    .font-size-64 {
      font-size: 24px !important;
    }
  }
  @media screen and (max-width: 400px) {
    .font-size-64 {
      font-size: 25px !important;
    }
  }
}
