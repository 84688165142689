#prizes-section {
  .gray-color {
    color: #86868b;
  }

  .pointer {
    cursor: pointer;
  }

  .arrow-btn {
    @media screen and (max-width: 991px) {
      width: 40px;
      height: auto;
    }
  }

  overflow: hidden;

  @media screen and (max-width: 1440px) {
    padding-bottom: 0px;
  }

  @media screen and (max-width: 991px) {
    padding-top: 2rem;
  }

  @media screen and (max-width: 600px) {
    padding-top: 0;
  }

  .description-991 {
    @media screen and (max-width: 991px) {
      max-width: 600px;
      width: 100%;
    }
  }

  .slider-col {
    min-height: 550px;

    @media screen and (max-width: 600px) {
      min-height: 460px;
      padding-bottom: 2rem;
    }

    @media screen and (max-width: 450px) {
      min-height: 430px;
    }
  }

  .bottom-0 {
    bottom: -50px;
    position: absolute;
  }

  .pos-absolute-slider {
    height: 490px;
    min-height: 480px;
    position: relative;

    @media screen and (max-width: 991px) {
      height: 450px;
      min-height: 450px;
    }

    @media screen and (max-width: 600px) {
      height: 420px;
      min-height: 420px;
    }

    @media screen and (max-width: 450px) {
      height: 400px;
      min-height: 400px;
    }
  }

  @media screen and (min-width: 992px) {
    .pos-absolute-slider {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      left: 60px;
    }
  }

  .slick-track {
    height: 100%;
  }

  @media screen and (min-width: 2000px) {
    .pos-absolute-slider {
      width: 128%;
    }
  }

  .info-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 992px) {
      align-items: center;
      margin-right: 0;
    }
  }

  .slider-box {
    background-image: url("./Assets/main-card-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    height: 340px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: #161616;
    padding: 1rem;
    margin: 1.5rem;
    // border-radius: 16px;
    transition: all 0.2s ease-in-out;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
    }

    @media screen and (max-width: 1300px) {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    @media screen and (max-width: 991px) {
      height: 300px;
      margin: 1rem;
      width: 250px;
    }

    @media screen and (max-width: 600px) {
      margin: 0rem;
      margin-block: 1rem;
      height: 250px;
      width: 200px;
    }

    @media screen and (max-width: 350px) {
      padding: 2rem;
    }
  }

  .slide-image {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .active-box-title {
    font-size: 32px;
    font-family: proxima-nova, sans-serif;
    font-weight: 700;

    @media screen and (max-width: 991px) {
      font-size: 24px;
    }
  }

  .bike-img {
    top: 5px;
    width: 300px !important;
    height: auto;

    @media screen and (max-width: 991px) {
      width: 230px !important;
      top: 5px;
    }

    @media screen and (max-width: 600px) {
      width: 200px !important;
      top: 5px;
    }
  }

  .basm-img {
    top: 80px;
    width: 300px !important;

    @media screen and (max-width: 991px) {
      width: 230px !important;
      top: 50px;
    }

    @media screen and (max-width: 600px) {
      width: 200px !important;
    }
  }

  .impulse-img {
    top: 20px;
    width: 110px !important;

    @media screen and (max-width: 991px) {
      width: 100px !important;
      top: 30px;
    }

    @media screen and (max-width: 600px) {
      width: 80px !important;
      top: 10px;
    }
  }

  .minter-img {
    top: 40px;
  }

  .hidr-img {
    top: 20px;
    width: 120px !important;

    @media screen and (max-width: 991px) {
      width: 100px !important;
      top: 30px;
    }

    @media screen and (max-width: 600px) {
      width: 90px !important;
      top: 10px;
    }
  }

  .ticket-img {
    top: 40px;
    width: 120px !important;
    height: auto;

    @media screen and (max-width: 991px) {
      width: 100px !important;
      top: 50px;
    }

    @media screen and (max-width: 600px) {
      top: 40px;
    }
  }

  .slick-active {
    .slider-box {
      background-image: url("./Assets/main-card-bg.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 380px;
      overflow: visible;
      transition-delay: 0.5s;

      &::after {
        content: "";

        background-color: rgba(255, 255, 255, 0);
      }

      @media screen and (max-width: 991px) {
        height: 350px;
      }

      @media screen and (max-width: 600px) {
        height: 280px;
      }
    }
  }

  .slick-slide {
    min-height: 460px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 991px) {
      min-height: 400px;
    }

    @media screen and (max-width: 600px) {
      min-height: 320px;
    }
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .slick-list {
    @media screen and (max-width: 991px) {
      max-height: 485px;
    }
  }

  .apply-effect {
    //transition: .2s;
    animation: fadeEffect ease-in 0.5s forwards;
  }

  @keyframes fadeEffect {
    from {
      opacity: 0;
    }

    to {
      opacity: 100;
    }
  }

  .check-prizes-btn {
    background-color: transparent;
    border: none;
    width: 230px;
    height: 48px;

    &:hover {
      .check-prizes-btn-shape {
        transform: translate(-50%, -50%) skew(-35deg);
      }
    }

    .check-prizes-btn-shape {
      background-color: #c80502;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) skew(-25deg);
      transition: 0.2s ease-out;
    }
    .check-prizes-btn-txt {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
    }

    @media screen and (max-width: 767px) {
      width: 180px;
      height: 38px;
    }
  }

  .overlay-gray {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    mix-blend-mode: color;
    z-index: 5;
  }

  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    z-index: 3;
    text-shadow: 0 0 10px #000000, 0 0 10px #000000, 0 0 10px #000000;
  }
}
