.motogp-raffle-form {
  //   height: 400px;
  width: 100%;
  margin-top: 4rem;

  .custom-margin {
    margin-left: auto !important;

    @media screen and (max-width: 991px) {
      margin: auto !important;
    }
  }

  @media screen and (max-width: 1200px) {
    margin-top: 3rem;
  }

  @media screen and (max-width: 550px) {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .main-container {
    @media screen and (max-width: 900px) {
      padding-right: 30px;
    }
    @media screen and (max-width: 550px) {
      padding: 0 !important;
    }
  }

  .motogp-raffle-form-container {
    max-width: 1140px;
    width: 100%;
    height: 450px;
    // margin-left: 200px;
    @media screen and (max-width: 1450px) {
      margin-left: 0;
    }
    @media screen and (max-width: 1300px) {
      max-width: 800px;
    }
    @media screen and (max-width: 991px) {
      height: 420px;
    }

    .motogp-form {
      z-index: 5;
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(68, 61, 61, 0.68);
      padding: 56px;

      @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        background-color: rgba(0, 0, 0, 0.6);
        -webkit-backdrop-filter: blur(20px) brightness(130%);
        backdrop-filter: blur(20px) brightness(130%);
      }

      @media screen and (max-width: 1300px) {
        padding: 40px;
      }

      @media screen and (max-width: 991px) {
        padding: 1.5rem;
      }

      @media screen and (max-width: 550px) {
        background-color: unset !important;
        padding: 0;
        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
          background-color: unset !important;
          -webkit-backdrop-filter: unset !important;
          backdrop-filter: unset !important;
        }
      }

      .motogp-input {
        width: 850px;
        height: 50px;
        padding: 0 8px;
        border: none;
        color: #ffffff;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.7);

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
          backdrop-filter: blur(20px) brightness(200%);
          -webkit-backdrop-filter: blur(20px) brightness(200%);
          background-color: rgba(255, 255, 255, 0.1);
        }

        @media screen and (max-width: 1300px) {
          width: 100%;
          height: 40px;
        }

        @media screen and (max-width: 991px) {
          height: 35px;
        }

        @media screen and (max-width: 767px) {
          font-size: 18px;
        }

        @media screen and (max-width: 550px) {
          font-size: 16px;
        }
      }

      .motogp-hero-btn-shape {
        border: 1px solid #ffffff;
      }

      .motogp-form-field {
        @media screen and (max-width: 1300px) {
          flex-direction: column;
          align-items: flex-start !important;
          .motogp-form-label {
            margin-bottom: 0.5rem;
            &.font-size-28 {
              font-size: 20px;
            }
          }
        }
        @media screen and (max-width: 991px) {
          .motogp-form-label {
            &.font-size-28 {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .motogp-form-footer {
    width: 850px;
    @media screen and (max-width: 1300px) {
      width: 100%;
    }
    @media screen and (max-width: 550px) {
      flex-direction: column-reverse;
      align-items: center !important;
      .motogp-hero-btn {
        // margin-right: 0 !important;
        margin-bottom: 1rem;
      }
    }
  }

  .motogp-raffle-form-border {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: 1px solid #ffffff;
    width: 100%;
    height: 100%;
    transform: translate(30px, -30px);
    @media screen and (max-width: 991px) {
      transform: translate(15px, -15px);
    }
    @media screen and (max-width: 550px) {
      display: none !important;
    }
  }
  .link-color {
    text-decoration: none;
    color: #c80502;
  }
  a.link-color:hover {
    color: #e91c19 !important;
  }
}
