#homepage-countdown {
  .time {
    text-align: center;
  }
  // .container-counter-new {
  //   max-width: 600px;
  // }
  .countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .all-times-bar {
    display: flex;
    .timebox {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .title {
        font-weight: 100;
      }
    }
  }

  .font-size-12 {
    font-size: 12px;
  }

  .font-size-32 {
    font-size: 32px;
    line-height: 1;
  }

  .first-col {
    @media screen and (min-width: 992px) {
      width: fit-content;
      width: -moz-fit-content;
    }
  }
}
