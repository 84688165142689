@import "../Styles/containers.scss";
@import "../Styles/fonts.scss";

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.app {
  font-family: moto-gp-regular, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.white-color {
  color: #ffffff;
}

.line-height-1 {
  line-height: 1;
}

.line-height-12 {
  line-height: 1.2;
}
