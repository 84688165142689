#faq-moto-gp {
  .gray-color {
    color: #86868b;
  }

  .dashes-img {
    width: 279px;
    height: auto;
  }

  .accordion-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
    padding-block: 1rem !important;
    background-color: transparent !important;

    &::after {
      display: none !important;
    }

    &:focus {
      box-shadow: none;
    }
    svg {
      width: 10px;
      height: auto;
      transition: 0.2s;
      transform: rotate(45deg);
    }
  }

  .collapsed {
    svg {
      transform: rotate(0) !important;
    }
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }

  .accordion-body {
    padding-bottom: 0.5rem;
    padding-top: 0;
  }

  .accordion-item {
    border: none !important;
    border-bottom: 1px solid rgba(200, 5, 2, 0.3) !important;
    border-radius: 0;
  }

  .accordion-collapse {
    a {
      text-decoration: none;
      margin-bottom: 0.25rem !important;
      transition: 0.2s;

      &:hover {
        color: white !important;
      }
    }
  }

  .accordion-button:not(.collapsed) {
    color: #000000 !important;
  }
  .link-color {
    color: #c80502;
  }
  a.link-color:hover {
    color: #e91c19 !important;
  }
}
