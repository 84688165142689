
#error-page {
    
    .cursor-pointer {
        cursor: pointer;
    }
    .line-height-11 {
        line-height: 1.1;
    }

    .motogp-hero-btn {
        background-color: transparent;
        border: none;
        width: 230px;
        height: 48px;
        @media screen and (max-width: 991px) {
          width: 180px;
          height: 40px;
        }
    
        &:hover {
          .motogp-hero-btn-shape {
            transform: translate(-50%, -50%) skew(-35deg);
          }
        }
    
        .motogp-hero-btn-shape {
          background-color: #c80502;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) skew(-25deg);
          transition: 0.2s ease-out;
        }
        .motogp-hero-btn-txt {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 5;
        }
      }
}