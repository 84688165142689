.motogp-raffle-hero {
  background-image: url("./assets/motogp-raffle-hero-bg.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  height: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    height: 1050px;
  }

  @media screen and (max-width: 991px) {
    height: 900px;
  }

  // @media screen and (max-width: 550px) {
  //   height: 1000px;
  // }

  @media screen and (max-width: 550px) {
    background-image: url("./assets/motogp-raffle-hero-bg-mobile.png");
    background-position: center;
  }

  .main-container {
    @media screen and (max-width: 550px) {
      height: 100%;
      justify-content: space-between !important;
    }
  }
  .motogp-raffle-hero-txt-mobile {
    @media screen and (min-width: 550px) {
      display: none !important;
    }
    @media screen and (max-width: 549px) {
      display: block !important;
    }
  }

  .motogp-raffle-hero-txt {
    max-width: 660px;
    text-align: center;
    @media screen and (max-width: 991px) {
      max-width: 500px;
    }
    @media screen and (max-width: 600px) {
      max-width: 400px;
      .font-size-24 {
        font-size: 18px;
      }
    }
    @media screen and (max-width: 549px) {
      margin-bottom: 1.5rem;
    }
  }
  .motogp-hero-btn {
    background-color: transparent;
    border: none;
    width: 230px;
    height: 48px;
    @media screen and (max-width: 991px) {
      width: 180px;
      height: 40px;
    }

    &:hover {
      .motogp-hero-btn-shape {
        transform: translate(-50%, -50%) skew(-35deg);
      }
    }

    .motogp-hero-btn-shape {
      background-color: #c80502;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) skew(-25deg);
      transition: 0.2s ease-out;
    }
    .motogp-hero-btn-txt {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
    }
  }
}
