.banner-section {
  background-color: #222222;
  margin-top: 56px;
  height: 50px;

  @media (max-width: 767px) {
    margin-top: 54px;
  }

  // .banner-li-container {
  //   h4 {
  //     cursor: pointer;
  //   }
  // }

  .container-navbar {
    width: 1010px !important;
    height: 100%;
    max-width: 100%;
    margin: auto;
  }

  .ul-container {
    max-width: 477px;
    width: 100%;

    @media screen and (max-width: 615px) {
      display: none !important;
    }
  }

  .li-color {
    color: #a1a1a6;
    list-style-type: none;
    cursor: pointer;
  }

  .register-btn {
    background-color: transparent;
    border: none;
    width: 144px;
    height: 30px;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      width: 110px;
      height: 27px;
    }

    .register-btn-shape {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) skew(-25deg);
      border: 1px solid white;
      transition: 0.2s ease-out;
    }
    .register-btn-txt {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
    }
  }

  .dropdown-menu.show {
    background-color: #222222;
  }

  .dropdown-toggle.btn.btn-primary {
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .dropdown-item {
    &:hover {
      background-color: transparent !important;
    }

    //   li {
    //     &:hover {
    //       color: white;
    //     }
    //   }
  }

  .mobile-banner {
    @media screen and (min-width: 615px) {
      display: none;
    }
  }
}
