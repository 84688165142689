// ============================================= 20 ======================================================
.font-size-16 {
  font-size: 16px;

  @media screen and (max-width: 1024px) {
    font-size: 15px;
  }

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }

  @media screen and (max-width: 767px) {
    font-size: 12px;
  }

  @media screen and (max-width: 575px) {
    font-size: 11px;
  }
}
.font-size-20 {
  font-size: 20px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 575px) {
    font-size: 13px;
  }
}

// ======================================================== 24 ====================================================
.font-size-24 {
  font-size: 24px;

  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }

  @media screen and (max-width: 991px) {
    font-size: 20px;
  }

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }

  @media screen and (max-width: 575px) {
    font-size: 16px;
  }

  @media screen and (max-width: 320px) {
    font-size: 14px;
  }
}

// ===================================================== 28 =========================================================

.font-size-28 {
  font-size: 28px;

  @media screen and (max-width: 1300px) {
    font-size: 24px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }

  @media screen and (max-width: 991px) {
    font-size: 20px;
  }

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }

  @media screen and (max-width: 575px) {
    font-size: 16px;
  }
}

// ============================================================== 56 =======================================================

.font-size-36 {
  font-size: 36px;

  @media screen and (max-width: 1024px) {
    font-size: 34px;
  }

  @media screen and (max-width: 991px) {
    font-size: 32px;
  }

  @media screen and (max-width: 767px) {
    font-size: 28px;
  }

  @media screen and (max-width: 575px) {
    font-size: 26px;
  }

  @media screen and (max-width: 320px) {
    font-size: 24px;
  }
}

// ============================================================== 56 =======================================================

.font-size-56 {
  font-size: 56px;

  @media screen and (max-width: 1024px) {
    font-size: 50px;
  }

  @media screen and (max-width: 991px) {
    font-size: 48px;
  }

  @media screen and (max-width: 767px) {
    font-size: 40px;
  }

  @media screen and (max-width: 575px) {
    font-size: 32px;
  }

  @media screen and (max-width: 320px) {
    font-size: 26px;
  }
}

// ======================================================== 64 ====================================================

.font-size-64 {
  font-size: 64px;
}

@media screen and (max-width: 1600px) {
  .font-size-64 {
    font-size: 60px;
  }
}
@media screen and (max-width: 1400px) {
  .font-size-64 {
    font-size: 56px;
  }
}
@media screen and (max-width: 1200px) {
  .font-size-64 {
    font-size: 50px;
  }
}
@media screen and (max-width: 991px) {
  .font-size-64 {
    font-size: 40px;
  }
}
@media screen and (max-width: 500px) {
  .font-size-64 {
    font-size: 32px;
  }
}
@media screen and (max-width: 300px) {
  .font-size-64 {
    font-size: 28px;
  }
}

// ======================================================== 120 ====================================================

.font-size-120 {
  font-size: 120px;
}

@media screen and (max-width: 1500px) {
  .font-size-120 {
    font-size: 100px;
  }
}

@media screen and (max-width: 1200px) {
  .font-size-120 {
    font-size: 80px;
  }
}

@media screen and (max-width: 991px) {
  .font-size-120 {
    font-size: 60px;
  }
}

@media screen and (max-width: 600px) {
  .font-size-120 {
    font-size: 40px;
  }
}

// ======================================================== font-family ====================================================

@font-face {
  font-family: "moto-gp-display";
  src: url("../Assets/fonts/MotoGPDisplay-Bold.ttf");
}

@font-face {
  font-family: "moto-gp-regular";
  src: url("../Assets/fonts/MotoGPText-Regular.ttf");
}

@font-face {
  font-family: "moto-gp-bold";
  src: url("../Assets/fonts/MotoGPText-Bold.ttf");
}

@font-face {
  font-family: "gotham-bold";
  src: url("../Assets/fonts/Gotham-Book.ttf");
}

.font-gotham-book {
  font-family: gotham-bold !important;
}

.moto-gp-display {
  font-family: moto-gp-display !important;
}

.moto-gp-regular {
  font-family: moto-gp-regular !important;
}

.moto-gp-bold {
  font-family: moto-gp-bold !important;
}
