footer {
  padding-bottom: 1.5rem !important;


  .card {
    border-radius: 0 !important;
  }


  .lg-footer-containers {
    display: flex;
    flex-direction: column;
  }

  .links-container-1, .bottom-footer {
    a {
      text-decoration: none;
      // color: $dark-color;
      margin-bottom: 0.5rem;
      cursor: pointer;
      // @extend .font-nunito-light;

      &:hover {
        color: #FFFFFF !important;
        text-decoration: none;
      }
    }
  }

  .utility-links-container {
    // width: 90px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .support-button-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .option {
      margin-bottom: 0;
    }

    #footer-faq-btn,
    #footer-contact-btn {
      width: 241.333px;
    }
  }

  .collapsable-footer-containers {
    display: none;

    .icon {
      font-size: 1rem;
      color: #FFFFFF;
    }
  }

  .social-media-container {
    // justify-content: space-evenly;

  }

  background-image: url("./Assets/footer_bg.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 1920px;
  @media screen and (max-width: 991px) {
      background-size: 1200px;
  }
  .container-bg {
    // background-image: url("./Assets/footer_bg.png");
    // background-position: center bottom;
    // background-repeat: no-repeat;
    // background-color: #000000;
    // min-height: 357px;
    min-height: 200px;

    @media screen and (max-width: 991px) {
        min-height: 100px;
    }
    .cryptodata-logo{
      z-index: 0;
      top: -105px;

      @media screen and (max-width: 991px) {
        width: 250px;
        
        height: auto;
        top: -65px;
      }
    }
  }

  .z-index-1 {
    z-index: 1;
  }
}

// media queries
@media screen and (max-width: 991px) {
  footer {
    .footer-description-container {
      padding-right: 0;
    }

    .social-media-container {
      margin-bottom: 2rem;
      justify-content: center;
    }

    .lg-footer-containers {
      display: none;
    }

    .footer-info,
    #footer-logo {
      text-align: center;
    }

    .collapsable-footer-containers {
      display: flex;

      .card {
        border-left: none;
        border-right: none;
        border-top: none;

        .collapse {
          .card-body {
            a {
              text-decoration: none;
              // color: $dark-color;
              margin-bottom: 0.5rem;
              cursor: pointer;
              // @extend .font-nunito-light;
        
              &:hover {
                color: #FFFFFF !important;
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    .small-screens-option {
      margin: 2rem 0;
    }

    #small-screens-faq-btn,
    #small-screens-contact-btn {
      width: 221.25px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 804px) {
  footer {
    .support-button-container {
      #footer-faq-btn {
        font-size: 14px !important;
      }
    }
  }
}

@media screen and (max-width: 302px) {
  footer {
    #small-screens-faq-btn,
    #small-screens-contact-btn {
      font-size: 12px;
      width: 200px;
    }
  }
}

@media screen and (max-width: 500px) {
  footer {
    #small-screens-faq-btn,
    #small-screens-contact-btn {
      width: 100%;
      height: 40px;
    }
  }
}

@media screen and (min-width: 767px) and (max-width: 868px) {
  footer {
    .social-media-container {
      #facebook-footer-link,
      #instagram-footer-link,
      #twitter-footer-link {
        margin-right: 2rem;
      }
    }
  }
}

@media screen and (max-width: 340px) {
  footer {
    .social-media-container {
      #facebook-footer-link,
      #instagram-footer-link,
      #twitter-footer-link {
        margin-right: 1.5rem;
      }
    }
  }
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

footer {

  .bottom-footer {
    border-top: 1px solid #222222;
  }

  .privacy-link{
    cursor: pointer !important;
    color:#FFFFFF;
    transition: .3s;

    &:hover {
      color:#bd9262;
    }
  }

  margin-top: 10rem !important;
  background-color: #000000;


  .email-sent {
    cursor:not-allowed !important;
  }

  .card-body, .card, .card-header, .card, .accordion, .accordion-item, .accordion-button {
    background-color: transparent !important;
    border: none;
    cursor: pointer;
  }

  .card-body {
    padding-top: 0;
  }

  .card{
    border-bottom: 1px solid #111111 !important;
  }

  .nav-container {
    svg {
      width: 28px !important;
      height: auto;
    }
  }

  .white-color {
    color: #FFFFFF
  }

  .gray-color {
    color: #86868B
  }

  .font-size-32 {
    font-size: 32px !important;

    @media screen and (max-width: 991px) {
      font-size: 26px !important;
    }
  }

  .font-size-18 {
    font-size: 18px !important;

    @media screen and (max-width: 991px) {
      font-size: 14px !important;
    }
  }

  .font-size-16 {
    font-size: 16px !important;

    @media screen and (max-width: 991px) {
      font-size: 12px !important;
    }
  }

  .font-size-14 {
    font-size: 14px !important;

    @media screen and (max-width: 991px) {
      font-size: 12px !important;
    }
  }

  .paragtaph-container{
    max-width: 370px;
  }


  .input-group {
    .big-input {
      background-color: #FFFFFF;
      border-right: none;
      border-radius: 30px 0 0 30px;
      padding-left: 1em;
      padding-right: 2em;
      height: 50px;
      margin-left: 1.25rem;
      color: #000000 !important;

      @media screen and (max-width: 991px){
        height: 36px;
      }

    }
    .small-input {
      display: none;
    }
    #basic-addon-2 {
      cursor: pointer;
      // border-top-left-radius: 0 !important;
      // border-bottom-left-radius: 0 !important;
    }
  }

  input:focus {
    z-index: 1 !important;
    box-shadow: none !important;
  }

  .btn-primary {
    background-color: #86868B !important;
    border-radius: 30px !important;
    background-image: none !important;
    transform: translateX(-1.25rem);
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 2;

    &:hover {
      background-image: none !important;
      background-color: #9d9da3 !important;
    }
    
    @media screen and (max-width: 991px) {
        height: 36px !important;
    }
  }

  @media screen and (max-width: 330px) {
    .big-input {
      display: none;
    }
    .small-input {
      display: block !important;
      background-color: #FFFFFF;
      border-right: none;
      border-radius: 30px 0 0 30px !important;
      padding-left: 1em;
      padding-right: 2em;
      height: 36px;
      margin-left: 1.25rem !important;
    }
  }
}

footer {

    .error-message {
        text-align: center;
        color: rgb(163, 0, 0);
    }

    .social-media-link {
        margin-right: 1rem;
    }

    .accordion-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;
        padding-block: .25rem !important;
        
        &::after {
            display: none !important;
        }
        
        &:focus {
            box-shadow: none;
        }
        svg {
            transition: .2s;
            transform: rotate(45deg);
        }
    }

    .collapsed {
        svg {
            transform: rotate(0) !important;
        }
    }

    .accordion-button:not(.collapsed) {
        box-shadow: none;
    }

    .accordion-body {
        padding-bottom: .5rem;
        padding-top: 0;
    }
    
    .accordion-collapse {
        a {
            text-decoration: none;
            margin-bottom: .25rem !important;
            transition: .2s;

            &:hover {
                color: white !important;
            }
        }
    }
}