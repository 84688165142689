#winners-moto-gp {
  .gray-color {
    color: #86868b;
  }

  .dashes-img {
    width: 279px;
    height: auto;
  }

  .link-color {
    color: #c80502;
  }
  a.link-color:hover {
    color: #e91c19 !important;
  }

  .card-ticket {
    border-radius: 8px;
    border: 1px solid #c80502;

    @media screen and (max-width: 991px) {
      width: 110px;
      height: auto;
    }
  }
  .impulse-ticket {
    border-radius: 8px;
    width: 140px;

    @media screen and (max-width: 991px) {
      width: 110px;
      height: auto;
    }
  }

  .text-content-container {
    max-width: 400px;
  }

  .line-height-11 {
    line-height: 1.1;
  }
}
